import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";

const variants = {
  open: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
    },
  },
  closed: { opacity: 0, x: "-100%" },
};

function Layout() {
  const [dropdown, setDropdown] = useState(false);
  const [theme, setTheme] = useState();
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  return (
    <div
      className={
        theme === "dark"
          ? "min-h-screen w-full font-Poppins bg-gray-900 text-white"
          : "min-h-screen w-full font-Poppins bg-gray-100 text-black"
      }
    >
      <nav className="w-full px-6 md:px-10 lg:px-40 py-6 flex items-center justify-between lg:justify-start">
        <div
          className="md:mr-12 lg:mr-24 flex items-center justify-center relative z-[200]"
          onClick={() => window.location.reload()}
        >
          <Link to="/">
            <img
              src="/logo.png"
              alt="logo"
              className={
                dropdown
                  ? theme === "light"
                    ? "h-8 brightness-0"
                    : theme === "dark" && "h-8 brightness-[100]"
                  : "h-8"
              }
            />
          </Link>
        </div>
        <div
          className="md:hidden w-8 relative z-[200]"
          onClick={() => setDropdown(!dropdown)}
        >
          <div
            className={`border-2 border-black ${
              theme === "dark" && "border-white"
            } mb-[6.5px]`}
          ></div>
          <div
            className={`border-2 border-black ${
              theme === "dark" && "border-white"
            } mb-[6.5px]`}
          ></div>
          <div
            className={`border-2 border-black ${
              theme === "dark" && "border-white"
            }`}
          ></div>
        </div>
        <motion.nav
          animate={dropdown ? "open" : "closed"}
          variants={variants}
          init
          className="lg:hidden absolute w-[200vw] h-screen z-[199] bg-accent p-4 left-0 top-0"
        >
          <motion.ul
            className="mt-16 ml-4"
            variants={{
              open: {
                transition: { staggerChildren: 0.8, delayChildren: 0.2 },
              },
              closed: {
                transition: { staggerChildren: 0.05, staggerDirection: -1 },
              },
            }}
          >
            <li className="mb-4" onClick={() => window.location.reload()}>
              <Link
                to="/about"
                className="hover:text-white hover:duration-200 text-2xl"
              >
                About
              </Link>
            </li>
            <li className="mb-4" onClick={() => window.location.reload()}>
              <Link
                to="/services"
                className="hover:text-white hover:duration-200 text-2xl"
              >
                Services
              </Link>
            </li>
            <li className="mb-4" onClick={() => window.location.reload()}>
              <Link
                to="/applications"
                className="hover:text-white hover:duration-200 text-2xl"
              >
                Applications
              </Link>
            </li>
            <li className="mb-4" onClick={() => window.location.reload()}>
              <Link
                to="/contact"
                className="hover:text-white hover:duration-200 text-2xl"
              >
                Contact
              </Link>
            </li>
            <li className="mb-4" onClick={() => window.location.reload()}>
              <Link
                to="/appeal"
                className="hover:text-white hover:duration-200 text-2xl"
              >
                Appeal
              </Link>
            </li>
            <li className="mb-4" onClick={() => window.location.reload()}>
              <Link
                to="/subscription"
                className="hover:text-white hover:duration-200 text-2xl"
              >
                Subscription
              </Link>
            </li>
            <li className="mb-4" onClick={() => window.location.reload()}>
              <Link
                to="https://panel.convivial-services.com/"
                className="hover:text-white hover:duration-200 text-2xl"
              >
                Dashboard
              </Link>
            </li>
            <li
              className="mb-4"
              onClick={() => {
                if (theme === "light") {
                  localStorage.setItem("convivial_services_theme", "dark");
                  setTheme("dark");
                } else if (theme === "dark") {
                  localStorage.setItem("convivial_services_theme", "light");
                  setTheme("light");
                }
                window.location.reload();
              }}
            >
              <Link
                className={`hover:text-white hover:duration-200 text-xl ${theme === "dark" && "text-gray-200"}`}
              >
                Switch To {theme === "dark" ? "Light" : "Dark"}
              </Link>
            </li>
          </motion.ul>
        </motion.nav>
        <div className="hidden md:flex w-[80%] justify-between">
          <ul className="hidden md:flex items-center font-[500] text-[15px]">
            {/* <li className="mx-6">
            <Link to="/" className="hover:text-accent hover:duration-200">HOME</Link>
          </li> */}
            <li className="mx-6">
              <Link
                to="/about"
                className="hover:text-accent hover:duration-200"
              >
                ABOUT
              </Link>
            </li>
            <li className="mx-6">
              <Link
                to="/services"
                className="hover:text-accent hover:duration-200"
              >
                SERVICES
              </Link>
            </li>
            <li className="group mx-6 ">
              <p className="hover:text-accent hover:cursor-pointer hover:duration-200">
                APPLICATIONS/FORMS
              </p>
              <ul
                className={
                  theme === "light"
                    ? "group-hover:block hidden absolute text-gray-700 group-hover:bg-gray-200 py-4 rounded-lg px-3"
                    : "group-hover:block hidden absolute text-white group-hover:bg-gray-700 py-4 rounded-lg px-3"
                }
              >
                <Link
                  to="/applications"
                  className="hover:text-accent hover:duration-200"
                >
                  <li className="mx-2 mb-3">
                    <p className="text-base">APPLICATIONS</p>
                    <p
                      className={`text-sm ${
                        theme === "light" ? "text-gray-500" : "text-gray-300"
                      }`}
                    >
                      Job applications (Web dev, Advertiser, Designer, etc.)
                    </p>
                  </li>
                </Link>

                <Link
                  to="/contact"
                  className="hover:text-accent hover:duration-200"
                >
                  <li className="mx-2 mb-3">
                    <p className="text-base">CONTACT</p>
                    <p
                      className={`text-sm ${
                        theme === "light" ? "text-gray-500" : "text-gray-300"
                      }`}
                    >
                      A form to contact the support team
                    </p>
                  </li>
                </Link>

                <Link
                  to="/appeal"
                  className="hover:text-accent hover:duration-200"
                >
                  <li className="mx-2 mb-0">
                    <p className="text-base">APPEAL</p>
                    <p
                      className={`text-sm ${
                        theme === "light" ? "text-gray-500" : "text-gray-300"
                      }`}
                    >
                      A form to appeal your discord punishment
                    </p>
                  </li>
                </Link>
              </ul>
            </li>
            <li className="group mx-6 ">
              <p className="hover:text-accent hover:cursor-pointer hover:duration-200">
                MANAGE
              </p>
              <ul
                className={
                  theme === "light"
                    ? "group-hover:block hidden absolute text-gray-700 group-hover:bg-gray-200 py-4 rounded-lg px-3"
                    : "group-hover:block hidden absolute text-white group-hover:bg-gray-700 py-4 rounded-lg px-3"
                }
              >
                <Link
                  to="/subscription"
                  className="hover:text-accent hover:duration-200"
                >
                  <li className="mx-2 mb-3">
                    <p className="text-base">SUBSCRIPTION</p>
                    <p
                      className={`text-sm ${
                        theme === "light" ? "text-gray-500" : "text-gray-300"
                      }`}
                    >
                      Manage and cancel your subscription
                    </p>
                  </li>
                </Link>

                <Link
                  to="https://panel.convivial-services.com/"
                  className="hover:text-accent hover:duration-200"
                >
                  <li className="mx-2 mb-0">
                    <p className="text-base">DASHBOARD</p>
                    <p
                      className={`text-sm ${
                        theme === "light" ? "text-gray-500" : "text-gray-300"
                      }`}
                    >
                      Manage your servers
                    </p>
                  </li>
                </Link>
              </ul>
            </li>
          </ul>
          <div
            onClick={() => {
              if (theme === "light") {
                localStorage.setItem("convivial_services_theme", "dark");
                setTheme("dark");
              } else if (theme === "dark") {
                localStorage.setItem("convivial_services_theme", "light");
                setTheme("light");
              }
              window.location.reload();
            }}
            className="hover:cursor-pointer hidden md:flex items-center justify-end"
          >
            {theme === "light" && (
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADAElEQVR4nO2YSWhUMRjHf7ValXaKdUFcLoogFoTqVbEu9OLNKj14cEMUpfaiIrigFi969VI9S0HrhifRarXFk2gVUVsXFBTUUnFpxQV1JPAfCMN05i1580Z9PwgMk5d/8iX5vi8JJCQkJPzL3ARuO9acAGwDLgJvgBkUgbSKC8YDrcCwpZsGKoiIMuBeVmem9ITQnAXct7QeAx/0O0VEjALu5jCkO6DebOC1NN4BjeqjU//N5S/YWmY7PZCG8bXpVt1x/b+ZItATYiUMRzTYF8DUrLoG1V2hxJloOfbyHPXlwCvVL6GEWalBGl8YiXX6ph+ooUQxM74WmFYgQnbKmBulbIwXahSOjTFPgPo4BmGSWRPQDvTJJ74Az4FTwGqtTCGmAF1WlLwKbAHmAVXKNbXAGmCMayMaNeB0gfIUWOZBbzSwExjMo/VTOcjJWcoIHbPETcZu0YxVqpiZbLZyyG9gl0f9amA9cFZbzqzyD2tS8h5D/Bw/MkZ8A7aONENWP9uBr2pjjAvCUbU/X+gY0u1jO2WM8OOUK2TMd2Chj3aZsT5Tv5twkLkrLJ8wK+GXZrW95rNdo9oNaduFpsnyiXzbKd82eyiN+T5OBy/VZi+OaJegceyg7LCS3y0d5Yfl1Ce1BW0u6/teYCyO6JeoiU5BqfUQrruAOfq+T+exmTjks4OLULmy90E5fZXCdR2wD3irPt4Di3UNMIUoDDF37jAYXxmJFHDaMiazMqExs7EROKfQmdbsRUkZcEZ9XXchWK8LUfYe3kD0pHQtTucIAL5oUNIzQhdkVKXC7jiKw371fyKowCTr4Lab+KizXlwCcchaiThJaRwmyAQic5iM5YLj0pDMg4GJ8ZTA1noUNl/EbcgBjaMtqECvBJYSH9XAgMbh5XaZk1YJmNfyOCgDOjw8JXl6CPgkoT0UfyU61Peg3o1DYS4zvyR4Scsblc9UAQvkEwOWEYtcdbAK+Ojh2O26dLpYiWwmA4eBO7pqRjHwId0c28I4dkJCQkJCwn/JH09qJa1eOMfYAAAAAElFTkSuQmCC" />
            )}
            {theme === "dark" && (
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjUlEQVR4nO2Zy2oUURCGD7lgDBNEScSFujfmFYwYN4KgIvEhkjDeNuJGXMW9PoG3BzGzFKITxcu4cSe6EQ3e0U8K/4GZY5yZc7r7TEf6h4bhTFfV//eprqlT41yFChWGCuAB8NBtdyC47Q4qISVD6XYE2ANMpBICjAO7Q+0GEfEJaAIzRQsBZoBHwAdgVzDhHo53AI/FaSNEDNAA1gJFbCiWxRyPJj5AgOfAvlwD/B3jWRExCheTTESPrQ8uAD7MR2zq5iHGAn/2gwIjwDxwU+3JG+CtPt8Cjtg9ns1e4It8phHhPcVpb+0osE5/2D3znu10HrubGcBV4JeIWpk+D8wCk7pmtdZOS7v3iisTgOsi9xVY8lNni9RbBr7J5porA4CTerom4liA3YLE/AROFMuyD4Ax4KWe7FKE/UpHKR9zwwKw2FEyRyLsR4En8nG2GJaDEbkvEvUMPi7Kx+182YWRaInEoQw+DsvHizyJ1YDXPep/w7t/U+u1DDGn5ONjLI8YIV1drAXX+lRiIWux8f5Fol2xZjP4mMs9tTK87Bcy+LgsH3fcsACcEwkroaOR5fepfCwWw3Lwc/UrEVmOsK/LtpX7STAUwBmRsXZjIcDuuGysvTnlygDgRoeYlV5ppnSqdzSNq64s4E9H2xaD8v6SKlJN15zW2u+E7cRqTGtTRMM46a2d7vi174WWn0429iH1e6Kjrp3y3vmnOhUAaybvqavd1GUDhbv6rouwdux90qOuN3xYz6P9lngbyKUZPhQ5skkxN0s2d6JoMRoeNGO2PvQfK7rFWMyd0cS3cH4A+JF4iN0EvgP7gwn3cX4w8d8KExbTlQWxQkqHSkjZ8D/tSCP3M3aFChVcKH4DW1NFx8NweQsAAAAASUVORK5CYII=" />
            )}
          </div>
        </div>
      </nav>

      <Outlet />
    </div>
  );
}

export default Layout;
