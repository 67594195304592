import React, { useEffect, useState } from "react";
import Notification from "../components/Notification";
import { motion } from "framer-motion";

const inputs = {
  "website-development-1": [
    {
      i: "input",
      type: "text",
      name: "name",
      placeholder: "Name",
    },
    {
      i: "input",
      type: "email",
      name: "email",
      placeholder: "Email",
    },
    {
      i: "input",
      type: "text",
      name: "discord_username",
      placeholder: "Discord Username",
    },

    {
      i: "input",
      type: "number",
      name: "age",
      placeholder: "Age",
    },
    {
      type: "text",
      name: "tech_stacks",
      placeholder:
        "Do you work with a well known stack? (MEAN, MERN, etc.)? If you do not work with a well known stack, please list the technologies you are familiar with.",
    },
    {
      type: "text",
      name: "schedule",
      placeholder: "What's your daily schedule like?",
    },
    {
      type: "text",
      name: "return",
      placeholder: "What do you expect out of this position?",
    },
  ],
  "website-development-2": [
    {
      type: "text",
      name: "AJAX",
      placeholder: "What is AJAX?",
    },
    {
      i: "input",
      type: "text",
      name: "css-selectors",
      placeholder: "What are CSS selectors? Name some.",
    },
    {
      type: "text",
      name: "Etag",
      placeholder: "How does an ETag work?",
    },

    {
      type: "text",
      name: "cache",
      placeholder:
        "If you were to cache data on the client side. How would you do it?",
    },
    {
      type: "text",
      name: "span",
      placeholder:
        "Can you explain the distinction between span and div tag in HTML5?",
    },
    {
      type: "text",
      name: "organize",
      placeholder: "How do you organize your JavaScript code?",
    },
    {
      type: "text",
      name: "elements",
      placeholder:
        "How do you vertically and horizontally center an element on the screen using CSS?",
    },
    {
      type: "text",
      name: "project",
      placeholder:
        "Tell me about a project you’re particularly proud of. What did you do that worked out well?",
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Do you want to work with us long term or short term?",
      not_required: true,
    },
    {
      i: "input",
      type: "text",
      name: "hours",
      placeholder: "How many hours per week would you be willing to work?",
      not_required: true,
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Anything else you want to tell us?",
      not_required: true,
    },
  ],
  "bot-development-1": [
    {
      i: "input",
      type: "text",
      name: "name",
      placeholder: "Name",
    },
    {
      i: "input",
      type: "email",
      name: "email",
      placeholder: "Email",
    },
    {
      i: "input",
      type: "text",
      name: "discord_username",
      placeholder: "Discord Username",
    },

    {
      i: "input",
      type: "number",
      name: "age",
      placeholder: "Age",
    },
    {
      i: "input",
      type: "text",
      name: "languages",
      placeholder: "What programming languages do you work with?",
    },
    {
      i: "input",
      type: "text",
      name: "discord_helper",
      placeholder: "What all Discord helper libraries are you familiar with?",
    },
    {
      type: "text",
      name: "schedule",
      placeholder: "What's your daily schedule like?",
    },
    {
      type: "text",
      name: "return",
      placeholder: "What do you expect out of this position?",
    },
  ],
  "bot-development-2": [
    {
      type: "text",
      name: "load",
      placeholder:
        "What is the most efficient way to load a list of messages spread across multiple servers while a sharded bot boots up?",
    },
    {
      type: "text",
      name: "mute",
      placeholder:
        "While coding a mute command? How do you make sure the user gets unmuted at a specific time?",
    },
    {
      type: "text",
      name: "redis_postgres",
      placeholder: "How can you interoperate on Redis and Postgres?",
    },
    {
      type: "text",
      name: "database",
      placeholder:
        "What would be the best choice for a database for a Discord bot?",
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Do you want to work with us long term or short term?",
      not_required: true,
    },
    {
      i: "input",
      type: "text",
      name: "hours",
      placeholder: "How many hours per week would you be willing to work?",
      not_required: true,
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Anything else you want to tell us?",
      not_required: true,
    },
  ],
  "design-1": [
    {
      i: "input",
      type: "text",
      name: "name",
      placeholder: "Name",
    },
    {
      i: "input",
      type: "email",
      name: "email",
      placeholder: "Email",
    },
    {
      i: "input",
      type: "text",
      name: "discord_username",
      placeholder: "Discord Username",
    },
    {
      i: "input",
      type: "number",
      name: "age",
      placeholder: "Age",
    },
    {
      i: "input",
      type: "number",
      name: "logos_banners",
      placeholder:
        "How many logos/banners and animations/videos have you approximately made in the past?",
    },
    {
      i: "input",
      type: "text",
      name: "comply",
      placeholder:
        "This job requires you to comply with our rules and Terms, which includes making sure you complete your projects, meet deadlines, behave properly with clients, informing us at least 2 day prior to taking a leave, etc. Do you agree to the Terms?",
    },
    {
      i: "input",
      type: "text",
      name: "schedule",
      placeholder: "What's your daily schedule like?",
    },
    {
      type: "text",
      name: "return",
      placeholder: "What do you expect out of this position?",
    },
    {
      type: "text",
      name: "resume",
      placeholder: "Do you have a Resume/Portfolio? Please link it here. ",
    },
    {
      type: "text",
      name: "experince",
      placeholder: "How much experience do you have in this field?",
    },
    {
      type: "text",
      name: "animating_characters",
      placeholder:
        "Are you capable of making animations or making animated characters?",
    },
    {
      i: "input",
      type: "text",
      name: "discord_creation",
      placeholder: "When did you start using discord? (Month, Year)",
    },
    {
      type: "text",
      name: "discord_creation",
      placeholder: "What puts you above all of the other candidates?",
    },
    {
      type: "text",
      name: "discord_creation",
      placeholder: "What are your strengths and weaknesses?",
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Do you want to work with us long term or short term?",
      not_required: true,
    },
    {
      i: "input",
      type: "text",
      name: "hours",
      placeholder:
        "Can you assure us that you will provide quality work and adhere to all deadlines?",
      not_required: true,
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Anything else you want to tell us?",
      not_required: true,
    },
  ],
  "moderator-1": [
    {
      i: "input",
      type: "text",
      name: "name",
      placeholder: "Name",
    },
    {
      i: "input",
      type: "email",
      name: "email",
      placeholder: "Email",
    },
    {
      i: "input",
      type: "text",
      name: "discord_username",
      placeholder: "Discord Username",
    },
    {
      i: "input",
      type: "number",
      name: "age",
      placeholder: "Age",
    },
    {
      i: "input",
      type: "text",
      name: "servers",
      placeholder:
        "Do you have any past discord server moderation and management experience? Please link the servers you have moderated/managed in the past.",
    },
    {
      i: "input",
      type: "text",
      name: "comply",
      placeholder:
        "This job requires you to comply with our rules and Terms, which includes covering your shift properly, informing us at least 2 day prior to taking a leave, etc. Do you agree to the Terms?",
    },
    {
      type: "text",
      name: "return",
      placeholder: "What do you expect out of this position?",
    },
    {
      type: "text",
      name: "resume",
      placeholder: "Do you have a Resume/Portfolio? Please link it here.",
    },
    {
      type: "text",
      name: "experince",
      placeholder: "How much moderating/managing experience do you have?",
    },
    {
      type: "text",
      name: "coding",
      placeholder:
        "Do you have any programming/coding knowledge? If yes, tell us briefly.",
    },
    {
      i: "input",
      type: "text",
      name: "discord_creation",
      placeholder: "When did you start using discord? (Month, Year)",
    },
    {
      type: "text",
      name: "discord_creation",
      placeholder: "What puts you above all of the other candidates?",
    },
    {
      type: "text",
      name: "value",
      placeholder: "What will you bring to Convivial Services?",
    },
    {
      type: "text",
      name: "discord_creation",
      placeholder: "What are your strengths and weaknesses?",
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Do you want to work with us long term or short term?",
      not_required: true,
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Anything else you want to tell us?",
      not_required: true,
    },
  ],
  "advertiser-1": [
    {
      i: "input",
      type: "text",
      name: "name",
      placeholder: "Name",
    },
    {
      i: "input",
      type: "email",
      name: "email",
      placeholder: "Email",
    },
    {
      i: "input",
      type: "text",
      name: "discord_username",
      placeholder: "Discord Username",
    },
    {
      i: "input",
      type: "number",
      name: "age",
      placeholder: "Age",
    },
    {
      i: "input",
      type: "text",
      name: "comply",
      placeholder:
        "This job requires you to comply with our rules and Terms. This includes no illicit or obnoxious advertising methods. Do you agree to the Terms?",
    },
    {
      type: "text",
      name: "return",
      placeholder: "What do you expect out of this position?",
    },
    {
      type: "text",
      name: "reward",
      placeholder:
        "Are you interested in working on commission? If not what pay basses do you expect",
    },
    {
      type: "text",
      name: "experince",
      placeholder: "Please describe your advertising experience.",
    },
    {
      i: "input",
      type: "text",
      name: "discord_creation",
      placeholder: "When did you start using discord? (Month, Year)",
    },
    {
      type: "text",
      name: "discord_creation",
      placeholder: "What puts you above all of the other candidates?",
    },
    {
      type: "text",
      name: "value",
      placeholder: "What will you bring to Convivial Services?",
    },
    {
      type: "text",
      name: "discord_creation",
      placeholder: "What are your strengths and weaknesses?",
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Do you want to work with us long term or short term?",
      not_required: true,
    },
    {
      i: "input",
      type: "text",
      name: "term",
      placeholder: "Anything else you want to tell us?",
      not_required: true,
    },
  ],
};

const WebsiteDeveloperApplication = () => {
  const [formData, setFormData] = useState({});
  const [notification, setNotification] = useState(false);
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.checkbox_1 || !formData.checkbox_2 || !formData.checkbox_3) {
      setNotification(
        <Notification message="Please check all the checkboxes at the bottom of the form" />
      );
      return;
    }
    for (let i = 0; i < inputs["website-development-1"].length; i++) {
      if (
        !formData[inputs["website-development-1"][i].name] ||
        formData[inputs["website-development-1"][i].name] === ""
      ) {
        if (inputs["website-development-1"][i].not_required === true) {
          continue;
        } else {
          setNotification(
            <Notification message="Please make sure fill in all the required fields (*) of the Application before submitting." />
          );
          return;
        }
      }
    }
    for (let i = 0; i < inputs["website-development-2"].length; i++) {
      if (
        !formData[inputs["website-development-2"][i].name] ||
        formData[inputs["website-development-2"][i].name] === ""
      ) {
        if (inputs["website-development-2"][i].not_required === true) {
          continue;
        } else {
          setNotification(
            <Notification message="Please make sure fill in all the required fields (*) of the Application before submitting." />
          );
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (notification) {
      const sleep = (time) =>
        new Promise((res) => setTimeout(res, time, "done sleeping"));
      sleep(5000).then((msg) => setNotification());
    }
  }, [notification]);
  return (
    <form
      method="POST"
      action="https://formsubmit.co/hiring@convivial-services.com"
      className="w-full pt-6"
    >
      {notification}
      <h1 className="text-2xl mb-4">
        <span className="text-accent font-[500]">Website Developer</span>{" "}
        Application
      </h1>
      {inputs["website-development-1"].map((input) => (
        <>
          <label for={input.name} className="ml-1 font-[500] mb-2">
            {input.placeholder.toUpperCase()}{" "}
            {!input.not_required && <span className="text-red-500">*</span>}
          </label>
          <br />
          {input.i ? (
            <input
              placeholder={input.placeholder}
              name={input.name}
              type={input.type}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          ) : (
            <textarea
              placeholder={input.placeholder}
              name={input.name}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          )}
          <br />
        </>
      ))}
      <h2 className="text-2xl font-[500] my-4">
        Since you are applying for the position of a web developer, here are
        some questions to test your basic skill in the field:
      </h2>
      {inputs["website-development-2"].map((input) => (
        <>
          <label for={input.name} className="ml-1 font-[500] mb-2">
            {input.placeholder.toUpperCase()}{" "}
            {!input.not_required && <span className="text-red-500">*</span>}
          </label>
          <br />
          {input.i ? (
            <input
              placeholder={input.placeholder}
              name={input.name}
              type={input.type}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          ) : (
            <textarea
              placeholder={input.placeholder}
              name={input.name}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          )}
          <br />
        </>
      ))}
      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_1: e.target.checked })
          }
        />
        <label>
          I consent to Convivial Services storing the informaiton submitted and
          using it to consider me for the position applied.{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center my-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_2: e.target.checked })
          }
        />
        <label>
          By submitting you agree To Our Rules & TOS{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center mt-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_3: e.target.checked })
          }
        />
        <label>
          You also agree to join our Discord Server and a 20 minute interview
          via Discord if shortlisted for the position{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <button
        className="mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full mb-8"
      >
        Submit Application
      </button>
    </form>
  );
};

const BotDeveloperApplication = () => {
  const [formData, setFormData] = useState({});
  const [notification, setNotification] = useState(false);
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.checkbox_1 || !formData.checkbox_2 || !formData.checkbox_3) {
      setNotification(
        <Notification message="Please check all the checkboxes at the bottom of the form" />
      );
      return;
    }
    for (let i = 0; i < inputs["bot-development-1"].length; i++) {
      if (
        !formData[inputs["bot-development-1"][i].name] ||
        formData[inputs["bot-development-1"][i].name] === ""
      ) {
        if (inputs["bot-development-1"][i].not_required === true) {
          continue;
        } else {
          setNotification(
            <Notification message="Please make sure fill in all the required fields (*) of the Application before submitting." />
          );
          return;
        }
      }
    }

    for (let i = 0; i < inputs["bot-development-2"].length; i++) {
      if (
        !formData[inputs["bot-development-2"][i].name] ||
        formData[inputs["bot-development-2"][i].name] === ""
      ) {
        if (inputs["bot-development-2"][i].not_required === true) {
          continue;
        } else {
          setNotification(
            <Notification message="Please make sure fill in all the required fields (*) of the Application before submitting." />
          );
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (notification) {
      const sleep = (time) =>
        new Promise((res) => setTimeout(res, time, "done sleeping"));
      sleep(5000).then((msg) => setNotification());
    }
  }, [notification]);
  return (
    <form
      method="POST"
      action="https://formsubmit.co/hiring@convivial-services.com"
      className="w-full pt-6"
    >
      {notification}
      <h1 className="text-2xl mb-4">
        <span className="text-accent font-[500]">Bot Developer</span>{" "}
        Application
      </h1>
      {inputs["bot-development-1"].map((input) => (
        <>
          <label for={input.name} className="ml-1 font-[500] mb-2">
            {input.placeholder.toUpperCase()}{" "}
            <span className="text-red-500">*</span>
          </label>
          <br />
          {input.i ? (
            <input
              placeholder={input.placeholder}
              name={input.name}
              type={input.type}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          ) : (
            <textarea
              placeholder={input.placeholder}
              name={input.name}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          )}
          <br />
        </>
      ))}
      <h2 className="text-2xl font-[500] my-4">
        Since you are applying for the position of a bot developer, here are
        some questions to test your basic skill in the field:
      </h2>
      {inputs["bot-development-2"].map((input) => (
        <>
          <label for={input.name} className="ml-1 font-[500] mb-2">
            {input.placeholder.toUpperCase()}{" "}
            {!input.not_required && <span className="text-red-500">*</span>}
          </label>
          <br />
          {input.i ? (
            <input
              placeholder={input.placeholder}
              name={input.name}
              type={input.type}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          ) : (
            <textarea
              placeholder={input.placeholder}
              name={input.name}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          )}
          <br />
        </>
      ))}
      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_1: e.target.checked })
          }
        />
        <label>
          I consent to Convivial Services storing the informaiton submitted and
          using it to consider me for the position applied.{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center my-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_2: e.target.checked })
          }
        />
        <label>
          By submitting you agree To Our Rules & TOS{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center mt-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_3: e.target.checked })
          }
        />
        <label>
          You also agree to join our Discord Server and a 20 minute interview
          via Discord if shortlisted for the position{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <button
        className="mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full mb-8"
      >
        Submit Application
      </button>
    </form>
  );
};

const GraphicDesignerApplication = () => {
  const [formData, setFormData] = useState({});
  const [notification, setNotification] = useState(false);
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.checkbox_1 || !formData.checkbox_2 || !formData.checkbox_3) {
      setNotification(
        <Notification message="Please check all the checkboxes at the bottom of the form" />
      );
      return;
    }
    for (let i = 0; i < inputs["design-1"].length; i++) {
      if (
        !formData[inputs["design-1"][i].name] ||
        formData[inputs["design-1"][i].name] === ""
      ) {
        if (inputs["design-1"][i].not_required === true) {
          continue;
        } else {
          setNotification(
            <Notification message="Please make sure fill in all the required fields (*) of the Application before submitting." />
          );
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (notification) {
      const sleep = (time) =>
        new Promise((res) => setTimeout(res, time, "done sleeping"));
      sleep(5000).then((msg) => setNotification());
    }
  }, [notification]);
  return (
    <form
      method="POST"
      action="https://formsubmit.co/hiring@convivial-services.com"
      className="w-full pt-6"
    >
      {notification}
      <h1 className="text-2xl mb-4">
        <span className="text-accent font-[500]">Graphic Designer</span>{" "}
        Application
      </h1>
      {inputs["design-1"].map((input) => (
        <>
          <label for={input.name} className="ml-1 font-[500] mb-2">
            {input.placeholder.toUpperCase()}{" "}
            {!input.not_required && <span className="text-red-500">*</span>}
          </label>
          <br />
          {input.i ? (
            <input
              placeholder={input.placeholder}
              name={input.name}
              type={input.type}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          ) : (
            <textarea
              placeholder={input.placeholder}
              name={input.name}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          )}
          <br />
        </>
      ))}
      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_1: e.target.checked })
          }
        />
        <label>
          I consent to Convivial Services storing the informaiton submitted and
          using it to consider me for the position applied.{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center my-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_2: e.target.checked })
          }
        />
        <label>
          By submitting you agree To Our Rules & TOS{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center mt-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_3: e.target.checked })
          }
        />
        <label>
          You also agree to join our Discord Server and a 20 minute interview
          via Discord if shortlisted for the position{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <button
        className="mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full mb-8"
      >
        Submit Application
      </button>
    </form>
  );
};

const ModeratorApplication = () => {
  const [formData, setFormData] = useState({});
  const [notification, setNotification] = useState(false);
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.checkbox_1 || !formData.checkbox_2 || !formData.checkbox_3) {
      setNotification(
        <Notification message="Please check all the checkboxes at the bottom of the form" />
      );
      return;
    }
    for (let i = 0; i < inputs["moderator-1"].length; i++) {
      if (
        !formData[inputs["moderator-1"][i].name] ||
        formData[inputs["moderator-1"][i].name] === ""
      ) {
        if (inputs["moderator-1"][i].not_required === true) {
          continue;
        } else {
          setNotification(
            <Notification message="Please make sure fill in all the required fields (*) of the Application before submitting." />
          );
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (notification) {
      const sleep = (time) =>
        new Promise((res) => setTimeout(res, time, "done sleeping"));
      sleep(5000).then((msg) => setNotification());
    }
  }, [notification]);
  return (
    <form
      method="POST"
      action="https://formsubmit.co/hiring@convivial-services.com"
      className="w-full pt-6"
    >
      {notification}
      <h1 className="text-2xl mb-4">
        <span className="text-accent font-[500]">Discord Moderator</span>{" "}
        Application
      </h1>
      {inputs["moderator-1"].map((input) => (
        <>
          <label for={input.name} className="ml-1 font-[500] mb-2">
            {input.placeholder.toUpperCase()}{" "}
            {!input.not_required && <span className="text-red-500">*</span>}
          </label>
          <br />
          {input.i ? (
            <input
              placeholder={input.placeholder}
              name={input.name}
              type={input.type}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          ) : (
            <textarea
              placeholder={input.placeholder}
              name={input.name}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          )}
          <br />
        </>
      ))}
      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_1: e.target.checked })
          }
        />
        <label>
          I consent to Convivial Services storing the informaiton submitted and
          using it to consider me for the position applied.{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center my-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_2: e.target.checked })
          }
        />
        <label>
          By submitting you agree To Our Rules & TOS{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center mt-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_3: e.target.checked })
          }
        />
        <label>
          You also agree to join our Discord Server and a 20 minute interview
          via Discord if shortlisted for the position{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <button
        className="mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full mb-8"
      >
        Submit Application
      </button>
    </form>
  );
};

const AdvertiserApplication = () => {
  const [formData, setFormData] = useState({});
  const [notification, setNotification] = useState(false);
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.checkbox_1 || !formData.checkbox_2 || !formData.checkbox_3) {
      setNotification(
        <Notification message="Please check all the checkboxes at the bottom of the form" />
      );
      return;
    }
    for (let i = 0; i < inputs["advertiser-1"].length; i++) {
      if (
        !formData[inputs["advertiser-1"][i].name] ||
        formData[inputs["advertiser-1"][i].name] === ""
      ) {
        if (inputs["advertiser-1"][i].not_required === true) {
          continue;
        } else {
          setNotification(
            <Notification message="Please make sure fill in all the required fields (*) of the Application before submitting." />
          );
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (notification) {
      const sleep = (time) =>
        new Promise((res) => setTimeout(res, time, "done sleeping"));
      sleep(5000).then((msg) => setNotification());
    }
  }, [notification]);
  return (
    <form
      method="POST"
      action="https://formsubmit.co/hiring@convivial-services.com"
      className="w-full pt-6"
    >
      {notification}
      <h1 className="text-2xl mb-4">
        <span className="text-accent font-[500]">Advertiser</span> Application
      </h1>
      {inputs["advertiser-1"].map((input) => (
        <>
          <label for={input.name} className="ml-1 font-[500] mb-2">
            {input.placeholder.toUpperCase()}{" "}
            {!input.not_required && <span className="text-red-500">*</span>}
          </label>
          <br />
          {input.i ? (
            <input
              placeholder={input.placeholder}
              name={input.name}
              type={input.type}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          ) : (
            <textarea
              placeholder={input.placeholder}
              name={input.name}
              onChange={(e) =>
                setFormData({ ...formData, [input.name]: e.target.value })
              }
              className={`w-full px-4 py-2 rounded-md text-accent mb-6 ${theme === "dark" && "bg-gray-800"}`}
            />
          )}
          <br />
        </>
      ))}
      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_1: e.target.checked })
          }
        />
        <label>
          I consent to Convivial Services storing the informaiton submitted and
          using it to consider me for the position applied.{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center my-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_2: e.target.checked })
          }
        />
        <label>
          By submitting you agree To Our Rules & TOS{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="flex items-center mt-2">
        <input
          type="checkbox"
          className="mr-2 accent-accent w-[20px] h-[20px]"
          onChange={(e) =>
            setFormData({ ...formData, checkbox_3: e.target.checked })
          }
        />
        <label>
          You also agree to join our Discord Server and a 20 minute interview
          via Discord if shortlisted for the position{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <button
        className="mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full mb-8"
      >
        Submit Application
      </button>
    </form>
  );
};

function Applications() {
  const [application, setApplication] = useState("website-developer");
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  return (
    <motion.div
      initial={{
        y: "2000px",
      }}
      animate={{
        y: 0,
        transition: {
          type: "spring",
          bounce: 0.2,
          duration: 0.4,
          delay: 0.2,
        },
      }}
      className="px-6 md:px-12 lg:px-40 min-h-[90vh] pb-20"
    >
      <select
        className={`${theme === "dark" && "bg-slate-800"} px-6 py-2 text-accent border-[2px] border-accent bg-inherit rounded-md font-[500]`}
        value={application}
        onChange={(e) => {
          setApplication(e.target.value);
        }}
      >
        <option value="website-developer">Website Developer Application</option>
        <option value="bot-developer">Bot Developer Application</option>
        <option value="graphic-design">Graphic Design Application</option>
        <option value="moderator">Moderator Application</option>
        <option value="advertiser">Advertiser Application</option>
      </select>
      {application === "website-developer" && <WebsiteDeveloperApplication />}
      {application === "bot-developer" && <BotDeveloperApplication />}
      {application === "graphic-design" && <GraphicDesignerApplication />}
      {application === "moderator" && <ModeratorApplication />}
      {application === "advertiser" && <AdvertiserApplication />}
    </motion.div>
  );
}

export default Applications;
