import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

function Appeal() {
  const [formData, setFormData] = useState({
    name: null,
    email: null,
    message: null,
    discord_username: null,
    discord_id: null,
    punishment: null,
    why: null,
    when: null,
    accept: null,
  });
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  return (
    <motion.div
      initial={{
        y: "2000px",
      }}
      animate={{
        y: 0,
        transition: {
          type: "spring",
          bounce: 0.2,
          duration: 0.4,
          delay: 0.2,
        },
      }}
      className="px-6 md:px-12 lg:px-40 py-16 flex flex-col min-h-[90vh] items-center justify-center"
    >
      <div>
        <h1 className="text-4xl font-[500] text-left w-full">
          <span className="text-accent">Appeal</span> Form
        </h1>
        <form
          mothod="POST"
          action="https://formsubmit.co/support@convivial-services.com"
          className="mt-4"
        >
          <label for="name" className="ml-1 font-[500] mb-2">
            NAME
          </label>
          <br />
          <input
            placeholder="Name"
            name="name"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className={`${theme === "dark" && "bg-gray-800"} w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <label for="email" className="ml-1 font-[500] mb-2">
            EMAIL
          </label>
          <br />
          <input
            placeholder="Email"
            name="email"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            className={`${theme === "dark" && "bg-gray-800"} w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <label for="di" className="ml-1 font-[500] mb-2">
            DISCORD USERNAME
          </label>
          <br />
          <input
            placeholder="Discord Username"
            name="di"
            onChange={(e) =>
              setFormData({ ...formData, discord_username: e.target.value })
            }
            className={`${theme === "dark" && "bg-gray-800"} w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <label for="dis" className="ml-1 font-[500] mb-2">
            DISCORD ID
          </label>
          <br />
          <input
            placeholder="Discord ID"
            name="dis"
            onChange={(e) =>
              setFormData({ ...formData, discord_id: e.target.value })
            }
            className={`${theme === "dark" && "bg-gray-800"} w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <label for="Punishment" className="ml-1 font-[500] mb-2">
            PUNISHMENT
          </label>
          <br />
          <input
            placeholder="Punishment"
            name="Punishment"
            onChange={(e) =>
              setFormData({ ...formData, punishment: e.target.value })
            }
            className={`${theme === "dark" && "bg-gray-800"} w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <label for="when" className="ml-1 font-[500] mb-2">
            WHY WERE YOU PUNISHED?
          </label>
          <br />
          <textarea
            placeholder="Why were you punished"
            name="when"
            onChange={(e) => setFormData({ ...formData, why: e.target.value })}
            className={`${theme === "dark" && "bg-gray-800"} w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <label for="when" className="ml-1 font-[500] mb-2">
            WHEN WERE YOU PUNISHED?
          </label>
          <br />
          <input
            placeholder="When were you punished?"
            name="when"
            onChange={(e) => setFormData({ ...formData, when: e.target.value })}
            className={`${theme === "dark" && "bg-gray-800"} w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <label for="when" className="ml-1 font-[500] mb-2">
            WHY SHOULD WE ACCEPT YOUR APPEAL AND ANYTHING ELSE YOU WANT TO ADD?
          </label>
          <br />
          <textarea
            placeholder="Why should we accept your appeal and anything else you want to add?"
            name="accept"
            onChange={(e) =>
              setFormData({ ...formData, accept: e.target.value })
            }
            className={`${theme === "dark" && "bg-gray-800"} w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <button
            type="submit"
            className="mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full mb-8"
          >
            Submit Form
          </button>
        </form>
      </div>
    </motion.div>
  );
}

export default Appeal;
