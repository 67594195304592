import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Home() {
  const [theme, setTheme] = useState();
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  return (
    <div className="w-full h-full lg:h-[90vh] flex flex-col lg:flex lg:flex-row px-6 md:px-10 lg:px-40 justify-between items-center">
      <motion.div
        initial={{
          x: "-2000px",
        }}
        animate={{
          x: 0,
          transition: {
            type: "spring",
            bounce: 0.2,
            duration: 0.8,
            delay: 0.2,
          },
        }}
        className="mt-4 lg:mt-0 lg:w-[50%] lg:pr-10"
      >
        <h1 className="text-5xl lg:text-6xl font-[500] mb-6">
          <span className="text-accent">Convivial</span> Services
        </h1>
        <p className="text-lg">
          Convivial Services is a business established in 2022, we provide
          development services and convivially serve you to satisfy all of your
          needs! We support services like discord bot developement, hosting,
          website development, etc.
        </p>
        <div className="mt-6 flex items-center">
          <Link to="/services">
            <button className=" bg-accent hover:bg-red-700 hover:duration-200 text-white py-[10px] px-[15px] rounded-full">
              <p>Get Started</p>
            </button>
          </Link>
          <Link to="/about">
            <div
              className={`flex items-center ${
                theme === "light" ? "text-gray-500" : "text-gray-300"
              } ml-4 hover:cursor-pointer hover:text-gray-600`}
            >
              <p className="mr-1">Learn More</p>
              {theme === "dark" && (
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtklEQVR4nO2UsQrCMBCGs1ToQ/QFdG67dhDcBXexIPhivoSDk7rqXMQXET4J3HC0aa7VSfSHG3L58yXcJXHuL2AClMBcxcxXBkiBK3BXse+tGpAAF7pqlGcFbIGjzD1iwEJMWaxXQA08gYMFrDzNgG0EtgPWHwHVyWoZL4HbW8A2TOXT0UBpRAdmin7gwkMD+QaYjgZG/F7VdwOLIRdbvJl4c+vpnRmuk19j7ZwEPodQlCbM/YReJap46P+ikuMAAAAASUVORK5CYII=" />
              )}
              {theme === "light" && (
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAsUlEQVR4nO2UOwrCQBRFTxPBRbgBrU1aC8FeSC8GBDfmJiys1FbrIG4kMBK4gWGYT4yV4oHLFPPmMLz5wB9gBBTA0spMnRkDN+Bh5RDrWgZcAeOktmpKYAecNPeMCXMVTRJnVQENcEwJFxLG2Eq2BzafCrudtWPLGrgPFboyrIN6W1gGZElCwpWkLjUwHSIMYbTmi4V5z4uNagwwTz29i+fphXLWmiiZ53Pwpegj4/d5AXiPPBdWAukTAAAAAElFTkSuQmCC" />
              )}
            </div>
          </Link>
        </div>
      </motion.div>
      <motion.div
        initial={{
          x: "2000px",
        }}
        animate={{
          x: 0,
          transition: {
            type: "spring",
            bounce: 0.2,
            duration: 0.8,
            delay: 0.2,
          },
        }}
        className="mt-12 lg:mt-0 lg:w-[50%] lg:pl-10 flex justify-center items-center"
      >
        <img src="hero-img.svg" />
      </motion.div>
    </div>
  );
}

export default Home;
