import React, { useEffect, useState } from "react";

function Notification({ message }) {
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  return (
    <div className={`max-w-[350px] fixed top-20 right-[0.5px] ${theme === "dark" ? "bg-red-400 border-r-red-600" : "bg-red-200 border-r-red-400"} p-4 border-r-8 rounded-l-lg z-10`}>
      {message}
    </div>
  );
}

export default Notification;