import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import About from "./pages/About";
import Services from "./pages/Services";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Applications from "./pages/Applications";
import Contact from "./pages/Contact";
import Appeal from "./pages/Appeal";
import Subscription from "./pages/Subscription";

export const backendBaseRoute = "https://backend.convivial-services.com"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="cart/:id" element={<Cart />} />
          <Route path="checkout/:id" element={<Checkout />} />
          <Route path="applications" element={<Applications />} />
          <Route path="contact" element={<Contact />} />
          <Route path="appeal" element={<Appeal />} />
          <Route path="subscription" element={<Subscription />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
