import React, { useEffect, useState } from "react";
import { discordBotPlans } from "./Services";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { backendBaseRoute } from "../App";
import Notification from "../components/Notification";

function Cart() {
  const [plan, setPlan] = useState(0);
  const [payment, setPayment] = useState("monthly");
  const [cost, setCost] = useState({
    subtotal: "$" + discordBotPlans[plan].price?.[payment],
    fee: "$0.00",
    total: "$" + discordBotPlans[plan].price?.[payment],
  });
  const [userData, setUserData] = useState({
    email: null,
    password: null,
    username: null,
    first_name: null,
    last_name: null,
  });
  const navigator = useNavigate();

  const handlePlanChange = (event) => {
    setPlan(event.target.value);
  };
  const handlePaymentChange = (event) => {
    setPayment(event.target.value);
  };
  const [notification, setNotification] = useState();

  useEffect(() => {
    if (plan && payment) {
      const subtotal = discordBotPlans[plan].price?.[payment];
      if (payment === "sixmonth") {
        const fee = 8.0;
        const total = parseFloat(subtotal) + fee;
        setCost({
          subtotal: "$" + subtotal,
          fee: "$" + fee,
          total: "$" + total,
        });
      } else if (payment === "yearly") {
        const fee = 15.0;
        const total = parseFloat(subtotal) + fee;
        setCost({
          subtotal: "$" + subtotal,
          fee: "$" + fee,
          total: "$" + total,
        });
      } else {
        setCost({
          subtotal: "$" + subtotal,
          fee: "$" + "0.00",
          total: "$" + subtotal,
        });
      }
    }
  }, [plan, payment]);
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);

  const handleCheckoutRedirect = async (e) => {
    e.preventDefault();
    const res = await axios.post(`${backendBaseRoute}/create/user`, {
      data: {
        plan: discordBotPlans[plan],
        payment: payment,
        cost: cost.total.split("$")[1],
        email: userData.email,
        password: userData.password,
        username: userData.username,
        first_name: userData.first_name,
        last_name: userData.last_name,
      },
    });
    if (res.data.ok) navigator("/checkout/" + res.data.data.token);
    if (!res.data.ok)
      setNotification(
        <Notification
          message={"Please Make Sure You Fill Out Your Login Information!"}
        />
      );
  };

  return (
    <div className="px-6 md:px-12 lg:px-40 flex flex-col lg:flex-row lg:justify-between mt-12">
      {notification}
      <div className="flex flex-col justify-start items-start lg:w-[90%] lg:pr-12">
        <h1 className="text-3xl font-[500]">Cart</h1>
        <div
          className={`mt-6 p-4 border-y-2 border-y-gray-300 ${
            theme === "dark" && "border-y-gray-600"
          } flex flex-col justify-start items-start w-full`}
        >
          <h2 className="font-[500] text-xl">Discord Bot Hosting Server</h2>
          <p className="text-gray-500">
            This is a service provided by Convivial Services that hosts discord
            bots.
          </p>
          <hr
            className={`${
              theme === "dark" && "border-gray-600"
            } w-full border-gray-300 mt-4`}
          />
          <p className="mt-4 text-xl font-[400] mb-1">Select A Server Plan:</p>
          <div className="flex flex-wrap lg:flex-nowrap items-center w-full justify-around">
            {discordBotPlans.map((iPlan) => (
              <div>
                <input
                  type="radio"
                  onChange={handlePlanChange}
                  id={iPlan.id}
                  name="plan"
                  value={iPlan.id}
                  checked={parseInt(plan) === parseInt(iPlan.id)}
                />
                <label
                  for={iPlan.id}
                  className="pl-2 font-[500] text-lg text-accent"
                >
                  {iPlan.ram}
                  <p
                    className={`text-base font-normal text-black ${
                      theme === "dark" && "text-white"
                    }`}
                  >
                    Swap/Ram: {iPlan.swap}
                  </p>
                  <p
                    className={`text-base font-normal text-black ${
                      theme === "dark" && "text-white"
                    }`}
                  >
                    CPU: {iPlan.cpu}
                  </p>
                  <p
                    className={`text-base font-normal text-black ${
                      theme === "dark" && "text-white"
                    }`}
                  >
                    Databases: {iPlan.database}
                  </p>
                  <p
                    className={`text-base font-normal text-black ${
                      theme === "dark" && "text-white"
                    }`}
                  >
                    Backups: {iPlan.backup}
                  </p>
                  <p
                    className={`text-base font-normal text-black ${
                      theme === "dark" && "text-white"
                    }`}
                  >
                    Disk: {iPlan.disk}
                  </p>
                </label>
              </div>
            ))}
          </div>
          <hr
            className={` w-full border-gray-300 mt-4 ${
              theme === "dark" && "border-gray-600"
            }`}
          />
          <p className="mt-4 text-xl font-[400] mb-1">
            Select Renewing Payment Time:
          </p>
          <div className="flex flex-wrap lg:flex-nowrap items-center w-full justify-around">
            <div>
              <input
                type="radio"
                id="monthly"
                onChange={handlePaymentChange}
                name="payment_time"
                value="monthly"
                checked={payment === "monthly"}
              />
               {" "}
              <label for="monthly" className="font-[500] text-lg text-accent">
                Monthly
                <p
                  className={`ml-6 text-base font-normal text-black ${
                    theme === "dark" && "text-white"
                  }`}
                >
                  ${discordBotPlans[plan].price.monthly}
                </p>
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="threemonths"
                onChange={handlePaymentChange}
                name="payment_time"
                value="threemonth"
                checked={payment === "threemonth"}
              />
               {" "}
              <label
                for="threemonths"
                className="font-[500] text-lg text-accent"
              >
                Every 3 Months
                <p
                  className={`ml-6 text-base font-normal text-black ${
                    theme === "dark" && "text-white"
                  }`}
                >
                  ${discordBotPlans[plan].price.threemonth}
                </p>
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="sixmonths"
                onChange={handlePaymentChange}
                name="payment_time"
                value="sixmonth"
                checked={payment === "sixmonth"}
              />
               {" "}
              <label for="sixmonths" className="font-[500] text-lg text-accent">
                Every 6 Months
                <p
                  className={`ml-6 text-base font-normal text-black ${
                    theme === "dark" && "text-white"
                  }`}
                >
                  ${discordBotPlans[plan].price.sixmonth}
                </p>
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="yearly"
                onChange={handlePaymentChange}
                name="payment_time"
                value="yearly"
                checked={payment === "yearly"}
              />
               {" "}
              <label for="yearly" className="font-[500] text-lg text-accent">
                Yearly
                <p
                  className={`ml-6 text-base font-normal text-black ${
                    theme === "dark" && "text-white"
                  }`}
                >
                  ${discordBotPlans[plan].price.yearly}
                </p>
              </label>
            </div>
          </div>
        </div>
        <div
          className={`py-4 my-4 flex flex-col justify-start items-start w-full border-b-2 border-b-gray-300 ${
            theme === "dark" && "border-b-gray-600"
          } pb-10`}
        >
          <h1 className="text-2xl font-[500]">Dashboard Login Information</h1>
          <p className="text-gray-500">
            If you already have your credientals put them in, but if you don't
            fill it out and
            <span className="text-accent font-[500]"> remember</span> them.
          </p>
          <div
            className={`${
              theme === "dark" && "border-y-gray-600"
            } mt-6 grid grid-cols-2 items-center w-full border-y-2 border-y-gray-200 py-4 px-4`}
          >
            <h3 className="text-lg">Email</h3>
            <input
              className={`${
                theme === "dark" && "bg-gray-800"
              } px-4 py-1 rounded-md`}
              onChange={(e) => {
                setUserData({ ...userData, email: e.target.value });
              }}
              placeholder="Email"
            />
          </div>
          <div
            className={`${
              theme === "dark" && "border-b-gray-600"
            } grid grid-cols-2 items-center w-full border-b-2 border-b-gray-200 py-4 px-4`}
          >
            <h3 className="text-lg">
              Username{" "}
              <span className="text-gray-500 text-base">
                (Only for new users)
              </span>
            </h3>
            <input
              className={`${
                theme === "dark" && "bg-gray-800"
              } px-4 py-1 rounded-md`}
              onChange={(e) => {
                setUserData({ ...userData, username: e.target.value });
              }}
              placeholder="Username"
            />
          </div>
          <div
            className={`${
              theme === "dark" && "border-b-gray-600"
            } grid grid-cols-2 items-center w-full border-b-2 border-b-gray-200 py-4 px-4`}
          >
            <h3 className="text-lg">
              First Name{" "}
              <span className="text-gray-500 text-base">
                (Only for new users)
              </span>
            </h3>
            <input
              className={`${
                theme === "dark" && "bg-gray-800"
              } px-4 py-1 rounded-md`}
              onChange={(e) => {
                setUserData({ ...userData, first_name: e.target.value });
              }}
              placeholder="First Name"
            />
          </div>
          <div
            className={`${
              theme === "dark" && "border-b-gray-600"
            } grid grid-cols-2 items-center w-full border-b-2 border-b-gray-200 py-4 px-4`}
          >
            <h3 className="text-lg">
              Last Name{" "}
              <span className="text-gray-500 text-base">
                (Only for new users)
              </span>
            </h3>
            <input
              className={`${
                theme === "dark" && "bg-gray-800"
              } px-4 py-1 rounded-md`}
              onChange={(e) => {
                setUserData({ ...userData, last_name: e.target.value });
              }}
              placeholder="Last Name"
            />
          </div>
          <div
            className={`${
              theme === "dark" && "border-b-gray-600"
            } grid grid-cols-2 items-center w-full border-b-2 border-b-gray-200 py-4 px-4`}
          >
            <h3 className="text-lg">
              Password{" "}
              <span className="text-gray-500 text-base">
                (Only for new users)
              </span>
            </h3>
            <input
              className={`${
                theme === "dark" && "bg-gray-800"
              } px-4 py-1 rounded-md`}
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setUserData({ ...userData, password: e.target.value });
              }}
            />
          </div>
        </div>
        <button
          onClick={handleCheckoutRedirect}
          className="hidden lg:block mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-max mb-8"
        >
          Continue to Checkout
        </button>
      </div>
      <div
        className={`w-full lg:w-[250px] flex flex-col justify-start items-start p-4 rounded border-2 border-gray-300 h-fit ${
          theme === "dark" && "border-gray-600"
        }`}
      >
        <h3 className="font-[500] text-xl mb-2">Cost</h3>
        <p className="text-gray-500">Subtotal: {cost?.subtotal || "?"}</p>
        <p className="text-gray-500">Maintenance Fee: {cost?.fee || "?"}</p>
        <p className="text-gray-500">Total: {cost?.total || "?"}</p>
      </div>
      <button
        onClick={handleCheckoutRedirect}
        className="lg:hidden mb-12 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full mt-4"
      >
        Continue to Checkout
      </button>
    </div>
  );
}

export default Cart;
