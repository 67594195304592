import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function About() {
  return (
    <div className="px-6 md:px-12 lg:px-40 mt-6 lg:mt-0">
      <div className="min-h-[90vh] flex flex-col lg:flex lg:flex-row items-center justify-start w-full">
        <motion.div
          initial={{
            x: "-1000px",
          }}
          animate={{
            x: 0,
            transition: {
              type: "spring",
              bounce: 0.2,
              duration: 0.4,
              delay: 0.6,
            },
          }}
          className="lg:w-[55%]"
        >
          <h1 className="text-4xl md:text-5xl font-[500]">
            <span className="text-accent">Who</span> are we and{" "}
            <span className="text-accent">why</span> should you choose us?
          </h1>
          <p className="mt-6 text-lg">
            Convivial Services is a business that was established in 2022 that
            provides a wide variety of development services. We provide services
            like hosting, website development, discord bot development, logo
            designs, etc. Our services are also affordable, good quality, and
            guaranteed to work.
          </p>
          <h1 className="mt-12 text-2xl font-[500] mb-4">Important Links:</h1>
          <ul className="list-disc ml-4 text-lg">
            <li className="hover:cursor-pointer hover:text-accent hover:duration-200 w-fit">
              <a href="/tos.pdf">Terms of Service (TOS)</a>
            </li>
            <li className="hover:cursor-pointer hover:text-accent hover:duration-200 w-fit">
              <a href="/privacy-policy.pdf">Privacy Policy</a>
            </li>
            <li className="hover:cursor-pointer hover:text-accent hover:duration-200 w-fit">
              <Link to="https://discord.gg/GeZzaWw8Rr">Discord Server</Link>
            </li>
          </ul>
        </motion.div>
        <motion.div
          initial={{
            x: "1000px",
          }}
          animate={{
            x: 0,
            transition: {
              type: "spring",
              bounce: 0.2,
              duration: 0.4,
              delay: 0.2,
            },
          }}
          className="lg:w-[45%] mt-12 mb-10 lg:mt-0 lg:pl-10 flex justify-center items-center"
        >
          <img src="about-img.svg" />
        </motion.div>
      </div>
    </div>
  );
}

export default About;
