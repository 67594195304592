import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const discordBotPlans = [
  {
    id: 0,
    ram: "512 MiB",
    swap: "512 MiB",
    cpu: "100%",
    database: "2",
    backup: "2",
    disk: "15125 MiB",
    price: {
      monthly: "1.25",
      threemonth: "3.37",
      sixmonth: "6.75",
      yearly: "13.45",
    },
  },
  {
    id: 1,
    ram: "1050 MiB",
    swap: "1050 MiB",
    cpu: "100%",
    database: "2",
    backup: "2",
    disk: "15125 MiB",
    price: {
      monthly: "2.00",
      threemonth: "5.40",
      sixmonth: "10.78",
      yearly: "21.54",
    },
  },
  {
    id: 2,
    ram: "2050 MiB",
    swap: "2050 MiB",
    cpu: "100%",
    database: "2",
    backup: "2",
    disk: "15125 MiB",
    price: {
      monthly: "3.75",
      threemonth: "10.12",
      sixmonth: "20.21",
      yearly: "40.39",
    },
  },
];

function Services() {
  const [value, setValue] = useState();
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  return (
    <motion.div
      initial={{
        y: "2000px",
      }}
      animate={{
        y: 0,
        transition: {
          type: "spring",
          bounce: 0.2,
          duration: 0.4,
          delay: 0.2,
        },
      }}
      className="px-6 md:px-12 lg:px-40 flex flex-col justify-center items-center h-[90vh]"
    >
      <h1 className="text-3xl font-[500] text-left mb-2">Services</h1>
      <select
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className={`px-4 py-2 bg-inherit border-2 border-black rounded-lg md:w-[50%] ${theme === "dark" && "bg-slate-800"}`}
      >
        <option value="">-- Please Select One --</option>
        <option value="discord-bot-hosting">Discord Bot Hosting</option>
        <option value="discord-bot-development">Discord Bot Development</option>
        <option value="discord-bot-repair">Discord Bot Repair</option>
        <option value="website-development">Website Development</option>
        <option value="website-repair">Website Repair</option>
        <option value="logo-and-banner-development">
          Logo & Banner Development
        </option>
      </select>
      {value === "discord-bot-hosting" && (
        <div className="mt-6 flex flex-col items-center">
          <p className="text-xl text-center">
            Our discord bot hosting plans can suit any discord bot. Please click
            the button below to head on with your purchase of a discord both
            hosting server.
          </p>
          <Link to={`/cart/0`}>
            <button className="mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-[30vw]">
              Purchase
            </button>
          </Link>
        </div>
      )}
      {value !== undefined && value !== "discord-bot-hosting" && (
        <div>
          <p className="mt-4 text-xl">
            Please join our{" "}
            <span className="text-accent hover:cursor-pointer">
              <Link to="https://discord.gg/GeZzaWw8Rr">Discord Server</Link>
            </span>{" "}
            to order your service.
          </p>
        </div>
      )}
    </motion.div>
  );
}

export default Services;
