import axios from "axios";
import React, { useEffect, useState } from "react";
import { backendBaseRoute } from "../App";
import Notification from "../components/Notification";
import { motion } from "framer-motion";

const Modal = ({ server }) => {
  const [userData, setUserData] = useState({
    username: "",
  });
  const [notification, setNotification] = useState();
  const handleDeleteServer = async (e, server) => {
    e.preventDefault();
    const response = await axios.post(`${backendBaseRoute}/delete/server`, {
      username: userData.username,
      server: server,
    });
    if (response.data.ok) {
      setNotification();
      window.location.reload();
    } else {
      setNotification(<Notification message={response.data.error} />);
    }
  };
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);

  return (
    <motion.div
      initial={{
        y: "2000px",
      }}
      animate={{
        y: 0,
        transition: {
          type: "spring",
          bounce: 0.2,
          duration: 0.4,
          delay: 0.2,
        },
      }}
      className="absolute top-0 left-0 w-screen h-screen z-10 flex justify-center items-center"
    >
      {notification}
      <div className="absolute top-0 left-0 w-screen h-screen z-20 bg-gray-500 opacity-75"></div>
      <div
        className={`absolute z-30 p-6 bg-gray-200 ${
          theme === "dark" && "bg-gray-700"
        } rounded-lg`}
      >
        <h1 className="font-[500] text-2xl mb-1">
          Please Confirm Your Username to Proceed
        </h1>
        <p className="text-base text-gray-500">
          This is used as a second form of security to make sure your servers
          can't be deleted by another user.
        </p>
        <input
          className={`px-4 py-1 rounded-md w-full mt-4 ${
            theme === "dark" && "bg-gray-800"
          }`}
          onChange={(e) => {
            setUserData({
              ...userData,
              username: e.target.value,
            });
          }}
          placeholder="Username"
        />
        <button
          onClick={async (e) => await handleDeleteServer(e, server)}
          className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full"
        >
          Delete Server
        </button>
      </div>
    </motion.div>
  );
};

function Subscription() {
  const [userData, setUserData] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [servers, setServers] = useState();
  const [notification, setNotification] = useState();
  const [modal, setModal] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      `${backendBaseRoute}/get/servers`,
      userData
    );
    if (response.data.ok) {
      setNotification();
      setServers(response.data.data);
    } else {
      console.log(response);
      setNotification(
        <Notification message="No user found with the specified data, please make sure you fill in all fields" />
      );
    }
  };
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);

  return (
    <div className="px-6 md:px-12 lg:px-40 my-4">
      {notification}
      {modal}
      <h1 className="text-3xl font-[500]">
        Manage <span className="text-accent">Subscription</span>
      </h1>
      <div
        className={`mt-6 grid grid-cols-2 items-center w-full border-b-2 border-y-gray-200 ${
          theme === "dark" && "border-b-gray-600"
        } py-4 px-4`}
      >
        <h3 className="text-lg">Email</h3>
        <input
          className={`px-4 py-1 rounded-md ${
            theme === "dark" && "bg-gray-800"
          }`}
          onChange={(e) => {
            setUserData({ ...userData, email: e.target.value });
          }}
          placeholder="Email"
        />
      </div>
      <div
        className={`grid grid-cols-2 items-center w-full border-b-2 border-b-gray-200 ${
          theme === "dark" && "border-b-gray-600"
        } py-4 px-4`}
      >
        <h3 className="text-lg">First Name</h3>
        <input
          className={`px-4 py-1 rounded-md ${
            theme === "dark" && "bg-gray-800"
          }`}
          onChange={(e) => {
            setUserData({ ...userData, first_name: e.target.value });
          }}
          placeholder="First Name"
        />
      </div>
      <div
        className={`grid grid-cols-2 items-center w-full border-b-0 border-b-gray-200 ${
          theme === "dark" && "border-b-gray-600"
        } py-4 px-4`}
      >
        <h3 className="text-lg">Last Name</h3>
        <input
          className={`px-4 py-1 rounded-md ${
            theme === "dark" && "bg-gray-800"
          }`}
          onChange={(e) => {
            setUserData({ ...userData, last_name: e.target.value });
          }}
          placeholder="Last Name"
        />
      </div>
      <button
        onClick={handleSubmit}
        className="mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-max mb-8"
      >
        Find Your Servers
      </button>
      {servers &&
        servers.map((server) => (
          <div className="w-full p-4 bg-gray-200 flex flex-col">
            <div className="flex justify-between items-center">
              <p className="text-accent font-[500] text-sm">SERVER</p>
              <button
                onClick={() => setModal(<Modal server={server} />)}
                className="text-lg hover:bg-slate-300 rounded-md"
              >
                🗑️
              </button>
            </div>
            <h1 className="text-xl font-[500]">{server.attributes.name}</h1>
            <p className="text-base font-[300]">
              {server.attributes.description}
            </p>
            <div className="flex">
              <p className="px-4 py-2 bg-gray-300 mt-2 rounded-md">
                CPU: {server.attributes.limits.cpu}%
              </p>
              <p className="px-4 py-2 bg-gray-300 mt-2 rounded-md ml-4">
                RAM: {server.attributes.limits.memory} MiB
              </p>
              <p className="px-4 py-2 bg-gray-300 mt-2 rounded-md ml-4">
                SWAP: {server.attributes.limits.swap} MiB
              </p>
              <p className="px-4 py-2 bg-gray-300 mt-2 rounded-md ml-4">
                DISK: {server.attributes.limits.disk} MiB
              </p>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Subscription;
