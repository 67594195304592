import React, { useEffect, useRef } from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { backendBaseRoute } from "../App";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#DD6252",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "20px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  layout: "multi",
};

const PaymentController = ({ id }) => {
  const stripePromise = useStripe();
  const elements = useElements();
  const paypal = useRef();
  const handleSubmitStripe = async (e) => {
    e.preventDefault();
    try {
      const paymentMethod = await stripePromise.createPaymentMethod({
        card: elements.getElement("card"),
        type: "card",
      });
      const res = await axios.post(`${backendBaseRoute}/create/clientSecret`, {
        token: id,
        paymentMethod: paymentMethod,
      });
      const info = res.data;
      if (!info.ok) {
        return alert("Payment Error");
      }
      const confirm = await stripePromise.confirmCardPayment(info.clientSecret);
      if (confirm.error) return alert("Payment unsuccessful!");
      const response = await axios.post(`${backendBaseRoute}/create/server`, {
        token: id,
      });
      if (response.data.ok) {
        return alert("Payment Success");
      }
      return alert("Error");
    } catch (err) {
      console.log(err);
      return alert("Payment Error!");
    }
  };

  let initialized = 0;

  useEffect(() => {
    if (initialized < 1) {
      initialized += 1;
	  axios.post(`${backendBaseRoute}/get/data`, {
        token: id,
      }).then(response => {
          const data = response.data.result;
          let plan;
          if (data.plan.ram.includes("512") && data.payment === "monthly") {
            plan = "P-0EY13271MP407415SMQKPF5A"
          } else if (data.plan.ram.includes("512") && data.payment === "threemonth") {
              plan = "P-8XE80422RL944784GMQKPHXI"
          } else if (data.plan.ram.includes("512") && data.payment === "sixmonth") {
              plan = "P-0DH2647657122663UMQKPJNY"
          } else if (data.plan.ram.includes("512") && data.payment === "yearly") {
              plan = "P-52B324840V605043CMQKPKHI"
          } else if (data.plan.ram.includes("1050") && data.payment === "monthly") {
              plan = "P-88S66381N8258891CMQYJTZI"
          } else if (data.plan.ram.includes("1050") && data.payment === "threemonth") {
              plan = "P-1DY123169M093163VMQYJWJY"
          } else if (data.plan.ram.includes("1050") && data.payment === "sixmonth") {
              plan = "P-07T769024U4578124MQYKAXY"
          } else if (data.plan.ram.includes("1050") && data.payment === "yearly") {
              plan = "P-4BN32683RP386342UMQYJ3TY"
          } else if (data.plan.ram.includes("2050") && data.payment === "monthly") {
              plan = "P-8E691044YV676712VMQYKFKI"
          } else if (data.plan.ram.includes("2050") && data.payment === "threemonth") {
              plan = "P-35X91053568074159MQYKGHI"
          } else if (data.plan.ram.includes("2050") && data.payment === "sixmonth") {
              plan = "P-4EH50721HD4264147MQYKHKY"
          } else if (data.plan.ram.includes("2050") && data.payment === "yearly") {
              plan = "P-1KN21635HT470913AMQYKIYI"
          }
          window.paypal
            .Buttons({
              style: {
                shape: "pill",
                color: "gold",
                layout: "horizontal",
                label: "paypal",
              },
              createSubscription: function (data, actions) {
                return actions.subscription.create({
                  description: "test",
                  plan_id: plan,
                });
              },
              onApprove: handleSubmitPaypal,
            })
            .render(paypal.current);
      });
    }
  }, []);

  const handleSubmitPaypal = async (data, actions) => {
    try {
      console.log(data)
      const response = await axios.post(`${backendBaseRoute}/create/server`, {
        token: id,
        id: data.subscriptionID
      });
      if (response.data.ok) {
        return alert("Payment Success");
      }
      return alert("Error");
    } catch (e) {
      return alert("Payment Error!");
    }
  };
  return (
    <form className="mt-4 w-full">
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <div className="flex flex-wrap lg:flex-nowrap mt-4">
        <button
          onClick={handleSubmitStripe}
          className="text-xl bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full mt-3 lg:w-[35%] w-full mb-11"
        >
          Pay w/ CC
        </button>
        <div
          ref={paypal}
          className="text-white font-bold lg:py-2 lg:px-4 rounded-lg w-full lg:w-[65%] lg:ml-4"
        ></div>
      </div>
    </form>
  );
};

function Checkout() {
  const { id } = useParams();
  const stripePromise = loadStripe(
    "pk_live_51MjWSaINGe113TurTpWDrqdrfawRtl0R9oyzlef2lRqvYr7nZCgvGipqqSZcDihmEmIw7BRpSpUBpG37A7Cihfli00R9DP80Tq"
  );

  return (
    <div className="px-6 md:px-12 lg:px-40 mt-6 py-8 flex flex-col justify-start items-start w-full">
      <h1 className="text-3xl font-[500] mb-6">Checkout</h1>
      <h1 className="text-2xl font-[500]">Payment Information</h1>
      <p className="text-gray-500">
        We do not collect any payment information, everything is handled by{" "}
        <span className="text-accent font-[500]">
          <Link to="https://stripe.com">stripe</Link>
        </span>
        .
      </p>

      <Elements stripe={stripePromise}>
        <PaymentController id={id} />
      </Elements>
    </div>
  );
}

export default Checkout;
