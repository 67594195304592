import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

function Contact() {
  const [formData, setFormData] = useState({
    name: null,
    email: null,
    message: null,
  });
  const handleSubmit = () => {};
  const [theme, setTheme] = useState(true);
  useEffect(() => {
    const theme = localStorage.getItem("convivial_services_theme");
    if (!theme) {
      localStorage.setItem("convivial_services_theme", "light");
      setTheme("light");
    } else {
      setTheme(theme);
    }
  }, []);
  return (
    <motion.div
      initial={{
        y: "2000px",
      }}
      animate={{
        y: 0,
        transition: {
          type: "spring",
          bounce: 0.2,
          duration: 0.4,
          delay: 0.2,
        },
      }}
      className="px-6 md:px-12 lg:px-40 flex flex-col min-h-[90vh] items-center justify-center"
    >
      <div>
        <h1 className="text-4xl font-[500] text-left w-full">
          <span className="text-accent">Contact</span> Form
        </h1>
        <form
          method="POST"
          action="https://formsubmit.co/support@convivial-services.com"
          className="mt-4"
        >
          <label for="name" className="ml-1 font-[500] mb-2">
            NAME
          </label>
          <br />
          <input
            placeholder="Name"
            name="name"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className={`${
              theme === "dark" && "bg-gray-800"
            } w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <label for="email" className="ml-1 font-[500] mb-2">
            EMAIL
          </label>
          <br />
          <input
            placeholder="Email"
            name="email"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            className={`${
              theme === "dark" && "bg-gray-800"
            } w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent mb-4`}
          />
          <br />
          <label for="message" className="ml-1 font-[500] mb-2">
            MESSAGE
          </label>
          <br />
          <textarea
            placeholder="Message"
            name="message"
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
            className={`${
              theme === "dark" && "bg-gray-800"
            } w-full lg:w-[40vw] px-4 py-2 rounded-md text-accent`}
          />
          <br />
          <button
            onClick={handleSubmit}
            className="mt-3 bg-accent hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg w-full mb-8"
          >
            Submit Form
          </button>
        </form>
      </div>
    </motion.div>
  );
}

export default Contact;
